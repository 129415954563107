import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleLeft, faAngleRight} from '@fortawesome/free-solid-svg-icons';
import heroBanner from '../assets/hero-banner.jpg';
import image1 from '../assets/image_1.jpg';
import image2 from '../assets/image_2.jpg';
import image3 from '../assets/image_3.jpg';
import costEfficient from '../assets/cost-efficient.jpg';
import security from '../assets/security.jpg';
import bullseyeLogo from '../assets/bullseye-thumb.png';
import foundationLogo from '../assets/sm-foundation-logo.png';

class HomePage extends Component {
    // constructor(props) {
    //     super(props)
    // }

    renderProducts() {
        const productsList = { "products" : [ 
                { name: "bullseye",  logo : bullseyeLogo, alt: "bullseye_logo" },
                { name: "smfoundation",  logo : foundationLogo, alt: "sm_foundation_logo" },
                { name: "3" },
                { name: "4" },
                { name: "5" },
                { name: "6" }
               
            ]}
        
        let renderList = Object.values(productsList.products).map(product => {
            return <li key={product.name} className="product-li col">
                { product.logo ? <img  src={product.logo} alt={product.alt} style={{ height: '100%', width: 'inherit', objectFit: 'scale-down'}}></img> : null}
            </li>
        })
        return (
            <React.Fragment>
                <ul className="nav nav-pills">
                    {renderList}
                </ul>
            </React.Fragment>
        )
    }
    render(){
        return (
            <React.Fragment>
                <header className="main-header over-flow-hidden" id="hero-banner">
                    <img  src={heroBanner} alt="hero-banner.jpg"  style={{height: '100%', maxHeight:'625px', width: '100%', objectFit: 'cover'}}/>
                    <div  className="col-lg-4 col-md-4 col-sm-4 overlay ml-5 h-100 px-5">
                        <h6 className="text-sub-color">SERVICE AVAILABLE NOW</h6>

                        <h3 className="text-white dax-font">Application Hosting</h3>
                        <div className="border-label-bottom mt-2 mb-4"></div>
                        <p className="text-justify text-white">
                            With our Application Hosting Service, we can help you seamlessly and painlessly build your cloud infrastructure so you can deploy any type of software application to a secure cloud platform where it is highly available to as many users as you need.
                        </p>
                        
                        <div className="text-center pt-5 mt-5">
                        <a href="#content-info" rel="noreferrer"><h3><FontAwesomeIcon icon={faAngleDown} color='#fff'/></h3></a>
                        </div>
                    </div>                    
                </header> 
                <main>
                    <div className="row mt-5 px-5">
                        <div className="col-lg-12 col-md-12 col-sm-12"  id="content-info">
                            <ul className="nav nav-pills nav-justified padding-both-ends-4">
                                <li className="nav-item px-2">
                                    <a className="nav-item nav-link active dax-font" data-toggle="tab" href="#flexibility"><h5><strong>Flexibility</strong></h5></a>
                                </li>
                                <li className="nav-item px-2">
                                    <a className="nav-item nav-link dax-font" data-toggle="tab" href="#scalable"><h5><strong>Scalable &amp; Cost-Efficient Solution</strong></h5></a>
                                </li>
                                <li className="nav-item px-2">
                                    <a className="nav-item nav-link dax-font" data-toggle="tab" href="#secure"><h5><strong>Secure</strong></h5></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row mt-5">
                            <div className="row tab-pane fade show active" id="flexibility" role="tabpanel" aria-labelledby="flexibility-tab">
                                <div className="col-lg-6 col-md-6 col-sm-12 pr-0">
                                    <img  src={image1} alt="image1.jpg"  style={{height: '100%', maxHeight:'25em', width: '100%', objectFit: 'cover'}}/>
                                </div>    
                                <div className="col-lg-6 col-md-6 col-sm-12 padding-5 ordinay-font-color">                                    
                                        <p>We can help you provision the cloud resources based on your requirements. Our cloud service provider enables you to select the compute power, operating system, programming language, database, storage capacity, and other services that you may need.</p> 
                                </div>
                            </div>
                            <div className="row tab-pane fade" id="scalable" role="tabpanel" aria-labelledby="scalable-tab">
                                <div className="col-lg-6 col-md-6 col-sm-12 pr-0">
                                    <img  src={costEfficient} alt="image1.jpg"  style={{height: '100%', maxHeight:'25em', width: '100%', objectFit: 'cover'}}/>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 padding-5 ordinay-font-color">
                                    <p>
                                        On demand provisioning. Your application can upscale or downscale instantly based on user demands.
                                    </p>
                                    <p>
                                        Pay-as-you-go. You only need to pay for the cloud computing resources that you use. This allows you to support your business growth seamlessly at low cost.
                                    </p>
                                </div>
                            </div>
                            <div className="row tab-pane fade" id="secure" role="tabpanel" aria-labelledby="secure-tab">
                                <div className="col-lg-6 col-md-6 col-sm-12 pr-0">
                                    <img src={security} alt="image1.jpg"  style={{height: '100%', maxHeight:'25em', width: '100%', objectFit: 'cover'}}/>  
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 padding-5 ordinay-font-color">
                                    <p>
                                    We value customer trust and satisfaction. This is why we are 100% committed in ensuring that all the important security measures for using cloud platform are strictly followed through an established Landing Zone environment.
                                    </p>
                                    <p>
                                    We implement important security measures such as but not limited to operational audits, multi-factor authentication, data encryption, threat detection, firewall installation, backup and recovery solutions, application and infrastructure monitoring, logging and notifications.
                                    </p>
                                </div>
                            </div>
                        </div>

                    <div className="row mt-5 custom-hr-tag"/>

                    <div className="row mt-5">                        
                        <div className="col-lg-6 col-md-6 col-sm-12 custom-pad-indicative-cost ordinay-font-color">
                            <h5 className="main-blue-font-color dax-font"><strong>Indicative Cost</strong></h5>
                            <div className="border-label-bottom mt-3 mb-3"></div>
                            <p>The cost will be based on the utilization of cloud resources and the complexity of the requirements or architecture of the infrastructure</p>
                            <ul>
                                <li><strong>Simple - </strong>Infrastructure for hosting a static website</li>
                                <li><strong>Moderate - </strong>Infrastructure for hosting a transactional website with basic database accesses</li>
                                <li><strong>Complex - </strong>Infrastructure for hosting a transactional website with advanced technologies such as Content Delivery Network, caching, analytics, containers and serverless</li>
                            </ul>

                            <p>
                                Please contact us to know more about the costing for Application Hosting. 
                            </p>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 pl-0">
                            <img  src={image2} alt="image2.jpg"  style={{height: '100%', maxHeight:'25em', width: '100%', objectFit: 'cover'}}/>
                        </div>
                    </div>

                    <div className="row mt-5"> 
                        <div className="col-lg-6 col-md-6 col-sm-12 pr-0">
                            <img  src={image3} alt="image3.jpg"  style={{height: '100%', maxHeight:'25em', width: '100%', objectFit: 'cover'}}/>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 padding-5 ordinay-font-color">
                            <h5 className="main-blue-font-color dax-font"><strong>Delivery Timeline</strong></h5>
                            <div className="border-label-bottom mt-3 mb-3"></div>
                            <p>
                            The delivery timeline varies based on the complexity of the requirements or architecture. Once the contract has been approved, the estimated delivery timeline is:</p>
                                <ul>
                                    <li><strong>Simple - </strong>~ 3 days</li>
                                    <li><strong>Moderate - </strong>~ 6 days</li>
                                    <li><strong>Complex - </strong>~ 11 days</li>
                                </ul>
                            <p>
                                Please contact us to know more about the delivery timeline for Application Hosting. 
                            </p>
                        </div>
                    </div>

                    <div className="row custom-hr-tag"/>

                    <div className="row mt-5 padding-both-ends-4"> 
                        <div className="col-lg-12 col-md-12 col-sm-12 pb-3">
                            <div className="row m-0">
                                <div>
                                    <h4 className="main-blue-font-color dax-font"><strong>Products</strong></h4>                            
                                    <div className="border-label-bottom mt-3 mb-3"></div>
                                </div>
                                <div className="pl-5 w-50">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </div>
                            </div>       
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                            {this.renderProducts()}
                        </div>
                    </div>

                    <div className="row mt-5 text-white"> 
                        <div className="col-lg-12 col-md-12 col-sm-12 main-blue-bg">
                            <div className="row  p-5">
                                <div className="col-sm-3 pr-3">
                                    <h5 className="dax-font">Services</h5>
                                    <h5 className="dax-font">Coming Soon</h5>
                                    <div className="border-label-bottom mt-2 mb-4"></div>
                                    <h6>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                    </h6>
                                    
                                    <div className="pt-4">
                                        <span className="pt-2 pb-2 pl-3 pr-3 sub-blue-bg mr-2">
                                            <FontAwesomeIcon icon={faAngleLeft} size="lg" color='#fff'/> 
                                        </span>
                                        <span className="pt-2 pb-2 pl-3 pr-3  sub-blue-bg  ml-2">
                                            <FontAwesomeIcon icon={faAngleRight} size="lg" color='#fff'/>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-sm-9">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="coming-soon-placeholder-div"></div>
                                                <span>
                                                    <h6 className="pt-3 mb-0">Cost Computaion: Compute, Storage, Database</h6>
                                                    <small className="sub-blue-font-color">May 2020</small>
                                                </span>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="coming-soon-placeholder-div"></div>
                                                <span>
                                                    <h6 className="pt-3 mb-0">Cost Computaion: Compute, Storage, Database</h6>
                                                    <small className="sub-blue-font-color">May 2020</small>
                                                </span>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="coming-soon-placeholder-div"></div>
                                                <span>
                                                    <h6 className="pt-3 mb-0">Cost Computaion: Compute, Storage, Database</h6>
                                                    <small className="sub-blue-font-color">May 2020</small>
                                                </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}

export default HomePage;