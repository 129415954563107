import React, { Component } from 'react';
import contactBanner from '../assets/contact-us-banner.jpg'
class ContactPage extends Component {
    render(){
        return (
            <React.Fragment>
                <header className="main-header over-flow-hidden" id="contact-banner">
                      
                    <img  src={contactBanner} alt="contact-banner.jpg"  style={{ height: '65%', maxHeight:'625px', width: '100%', objectFit: 'cover'}}/>
                    <div  className="col-sm-12 contact-overlay w-100 text-white text-center">  
                        <h2><strong>Contact us</strong></h2> 
                        <p>Want to get in touch? We'd love to hear from you. Here's how you can reach us...</p>
 
                    <div className="col-sm-12 contact-div-padding">
                        <div className="row ordinay-font-color">
                            <div className="col-sm-4 bg-white contact-div-child">
                                <p>Contact Info</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>

                            <div className="col-sm-4 bg-white contact-div-child">
                                <p>Contact Info</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>

                            <div className="col-sm-4 bg-white contact-div-child">
                                <p>Contact Info</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                    </div>

                    </div>     
                </header>
            </React.Fragment>
        )
    }
}

export default ContactPage;