import React, { Component } from 'react';
import faqBanner from '../assets/faq-banner.jpg'

class FAQPage extends Component {
    render(){
        return (
            <React.Fragment>
                <header className="main-header over-flow-hidden" id="hero-banner">
                    <img  src={faqBanner} alt="hero-banner.jpg"  style={{height: '100%', maxHeight:'625px', width: '100%', objectFit: 'cover'}}/>
                    <div  className="faq-overlay h-100 w-100">
                        <div className="ml-5">
                            <h3 className="text-white"><strong>Application Hosting</strong></h3>
                            <div className="border-label-bottom mt-2 mb-4"></div>
                            
                            <h6 className="text-white">Frequently Asked Questions</h6>
                        </div>
                    </div>                    
                </header> 
                <main>
                    <div className="col-sm-12 pl-5 pr-5 pt-5">
                        <div className="collapse-arrow">
                            <h5>
                                <button type="button" className="btn btn-link main-blue-font-color" data-toggle="collapse" data-target="#faq_1">
                                    <h5><strong>Why would I host my application on cloud server instead of on premise?</strong>
                                    {/* <span className="icon-caret float-right"><FontAwesomeIcon icon={faAngleRight} aria-labelledby="expand" /></span> */}
                                    </h5>
                                </button>
                            </h5>
                        </div>

                        <div className="collapse show pl-3 w-75" id="faq_1">
                            <p>Cloud-based hosting offers functionality on a pay-as-needed basis. With this option, you can effectively scale your resources based on your needs.</p>
                            <p>Other benefits of cloud computing are listed below:</p>
                            <ul>
                                <li>
                                    <strong>Technical deployment</strong> - faster and simpler, there is no need for expensive on-site infrastructure
                                </li>
                                <li>
                                    <strong>Accessibility</strong> - you can access your resources, data, or application anywhere and anytime
                                </li>
                                <li>
                                    <strong>Maintenance</strong> - you do not need to invest time and money in maintaining the software or hardware your application is installed on. Maintenance of these resources is handled by the Cloud Provider                                
                                </li>
                            </ul>
                        </div>

                        <div className="collapse-arrow">
                            <h5>
                                <button type="button" className="btn btn-link main-blue-font-color" data-toggle="collapse" data-target="#faq_2">
                                    <h5><strong>How secure it is to host an application in cloud?</strong>
                                    {/* <span className="icon-caret float-right"><FontAwesomeIcon icon={faAngleRight} aria-labelledby="expand" /></span> */}
                                    </h5>
                                </button>
                            </h5>
                        </div>

                        <div className="collapse show pl-3 w-75" id="faq_2">
                            <p>Security is one of the deciding factors when choosing between application hosting in cloud and on premise platform.</p>
                            <p>Cloud computing offers extensive list of services and resources for security to ensure that data are protected from any threats, corruption, loss or leaks.</p>
                            <p>Some of the security measures that cloud computing offers are the following:</p>
                            <ul>
                                <li>operational audits</li>
                                <li>managing access and roles</li>
                                <li>multi-factor authentication</li>
                                <li>data encryption</li>
                                <li>threat detection</li>
                                <li>firewall installation</li>
                                <li>backup and recovery</li>
                                <li>application and infrastructure monitoring</li>
                                <li>logging and notification</li>
                            </ul>
                        </div>

                        <div className="collapse-arrow">
                            <h5>
                                <button type="button" className="btn btn-link main-blue-font-color" data-toggle="collapse" data-target="#faq_3">
                                    <h5><strong>How long will it take to build the cloud infrastructure for hosting an application?</strong>
                                    {/* <span className="icon-caret float-right"><FontAwesomeIcon icon={faAngleRight} aria-labelledby="expand" /></span> */}
                                    </h5>
                                </button>
                            </h5>
                        </div>

                        <div className="collapse show pl-3 w-75" id="faq_3">
                            <p>The duration for hosting an application will depend on the complexity of your requirements, architecture or application. To know more about the duration, you can refer to our home page.</p>
                        </div>
                        

                    </div>
                </main>
            </React.Fragment>
        )
    }
}

export default FAQPage;