import React, { Component } from 'react';
import aboutBanner from '../assets/about-us.jpg'
class AboutPage extends Component {
    render(){
        return (
            <React.Fragment>
                <header className="main-header over-flow-hidden" id="contact-banner">
                <img  src={aboutBanner} alt="about-banner.jpg"  style={{ height: '65%', maxHeight:'625px', width: '100%', objectFit: 'cover'}}/>
                    <div  className="col-sm-12 about-overlay w-100 text-white text-center"> 
                    <h2><strong>About us</strong></h2>
                    <div className=" about-div-padding "></div>
                    <div className="col-sm-12">
                        <div className="row ordinay-font-color px-5 text-justify">
                            <p>
                                For SM Group BU’s and BU ITS who want business continuity & stability 24/7, as well as to launch their products easily, quickly and reliably, <strong>SMIC ITSS Cloud Center of Excellence (CCoE) will provide</strong> highly available, cost effective, transparent, flexible, scalable, secure and resilient infrastructure.
                            </p>
                            <p>
                                Unlike external suppliers, SMIC ITSS Cloud Center of Excellence is a family that cares for each other, who will take the best practices learned from traditional IT systems and apply innovation and automation on cloud.
                            </p>
                        </div>
                    </div>
                    </div>                    
                </header> 
            </React.Fragment>
        )
    }
}

export default AboutPage;