import React, { Component } from 'react';
import {  NavLink,  Route, Switch, Router, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history'
import HomePage from './components/Home';
import FAQPage from './components/Faq';
import ContactPage from './components/Contact';
import AboutPage from './components/About';

import logo from './assets/itss-logo-catalogue.png';

const history = createBrowserHistory();

class App extends Component {
  constructor(props) {
    super(props)

    this.renderNavBar = this.renderNavBar.bind(this)
  }
  
  renderNavBar() {
    return <React.Fragment>
      <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-white px-5 dax-font" data-testid="header-navbar">
        <a className="navbar-brand" href="/">
          <img src={logo} alt="logo.png" style={{ maxWidth: '275px', objectFit: 'contain'}}/>
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink to={'/home'} className="nav-link" activeClassName="active"><h5><strong>HOME</strong></h5></NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={'/about'} className="nav-link" activeClassName="active"><h5><strong>ABOUT</strong></h5></NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={'/faq'} className="nav-link" activeClassName="active"><h5><strong>FAQ</strong></h5></NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={'/contact'} className="nav-link" activeClassName="active"><h5><strong>CONTACT</strong></h5></NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </React.Fragment>
  }

  renderFooter(){
    return <div className="footer px-5 dax-font">
        <ul className="nav">
            <li className="nav-item">
              <NavLink to={'/home'} className="nav-link" activeClassName="active"><h6><strong>Home</strong></h6></NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={'/about'} className="nav-link" activeClassName="active"><h6><strong>ABOUT</strong></h6></NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={'/faq'} className="nav-link" activeClassName="active"><h6><strong>FAQ</strong></h6></NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={'/contact'} className="nav-link" activeClassName="active"><h6><strong>CONTACT</strong></h6></NavLink>
            </li>
        </ul>
        <div className="border-label-bottom mt-2 mb-2 ml-3"></div>
        <div className="ml-3">2020 Copyright © SM Investments Corporation IT Shared Service. All rights reserved.</div>
    </div>
  }

  renderMainContent() {
    return  <React.Fragment>
        <Switch>
          <Redirect from="/" exact to="/home" /> 
          <Route  path="/home" component={HomePage} />
          <Route path="/faq" component={FAQPage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/about" component={AboutPage} />
        </Switch>
    </React.Fragment>
  }
  render() {
    return (
      <Router history={history}>
        {this.renderNavBar()}
        <div className="container-fluid pl-0 pr-0">
            {this.renderMainContent()}
        </div>
        {this.renderFooter()}
      </Router>
    )
  }
}

export default App;
